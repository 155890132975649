export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDpV41LTqA1M4iUScS7HveSh6HECpbOY_I",
    authDomain: "checkteam-f3985.firebaseapp.com",
    projectId: "checkteam-f3985",
    storageBucket: "checkteam-f3985.appspot.com",
    messagingSenderId: "266088261760",
    appId: "1:266088261760:web:72f88a927f3ff680289e65",
    measurementId: "G-1ZZBNN61D6"
  },
  googleTagManagerId: 'GTM-5WCSNSWS',
  // apiUrl: 'http://localhost:8080'
  apiUrl: 'http://localhost:5000'
  // apiUrl: 'https://dev-rest.checkteam.co.kr'
  // apiUrl: 'https://rest.checkteam.co.kr'
};
